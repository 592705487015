// Variables first
@import 'app/common/variables';
// Fonts
@import 'common/fonts';
//Custom Icons
@import 'fonts/IcoBcome/style.css';
// Bootstrap
@import 'bootstrap/bootstrap';
// Layout
@import 'app/layout/layout';
@import 'app/layout/layout-extra';
@import 'app/layout/layout-animation';
@import 'app/layout/top-navbar';
@import 'app/layout/sidebar';
@import 'app/layout/offsidebar';
@import 'app/layout/user-block';
@import 'app/layout/settings';
// Common
@import 'app/common/page-loader';
@import 'app/common/typo';
@import 'app/common/bootstrap-reset';
@import 'app/common/bootstrap-custom';
@import 'app/common/button-extra';
@import 'app/common/placeholder';
@import 'app/common/cards';
@import 'app/common/circles';
@import 'app/common/dropdown-extra';
@import 'app/common/half-float';
@import 'app/common/animate';
@import 'app/common/slim-scroll';
@import 'app/common/inputs';
@import 'app/common/utils';
@import 'app/common/print';
@import 'app/common/pill.scss';
@import 'app/common/tooltip.scss';
@import 'app/common/shadows.scss';

//UI
@import '../UI/atoms/Tooltips/Tooltips.scss';
// Theme
@import 'app/theme/main';
// Components
@import 'app/component/Header';
@import 'app/component/Home';
@import 'app/component/LoginForm';
@import 'app/component/EditProfileForm';
@import 'app/component/NewUser';
// Elements
@import 'app/element/Notification';
@import 'app/element/Button';

/* Chrome, Safari, Edge, Opera */

body {
  font-family: $font-sfpro-regular;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

* {
  box-sizing: border-box;
}

p {
  white-space: break-spaces;
}

.invalid-feedback {
  font-weight: normal !important;
  text-transform: none !important;
  font-size: 12px;
  font-family: 'SFProTextRegular';
}

.smart-label-card-detail-arrows {
  width: 24px;
  height: 24px;
  cursor: pointer;
  img {
    width: 8px;
    height: 8px;
  }
}
#icon-arrow-left {
  transform: rotate(180deg);
}
.not-allowed{
  cursor: not-allowed;
}

.information-navigation {
  & a.text-yellow {
    text-decoration: underline;
  }
}

.text-yellow-underline:hover {
  text-decoration: underline;
  text-decoration-color: #BE8F1F;
}

.w-49 {
  width: 49%;
  min-width: 500px;

  @media(max-width: 1145px) {
    width: 100%;
  }

}

.tooltips-lca-benchmark .tooltiptext {
  margin-top: 60px;
}
.mantainance-page {
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #1C1C30;
    margin-bottom: 16px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #706F7A;
  }
}

.scope-rotate-icon {
  transition: all 0.5s ease;
	transform-origin: 50% 50%;
  border-radius: 8;
}

.eco-calculator-images {
  width: 30px;
  height: 30px;
}

.l-0 {
  left: 0;
}

.lca-statistics-card {
  min-width: 48%;
  height: 191px;

  table {
    tbody {
      tr {
        td {
          &:first-child {
            width: 10%;
          }
          &:nth-child(2) {
            width: 100%;
            max-width: 135px;
          }
        }
      }
    }
  }

  @media (min-width: 1500px) {
    min-width: 23%;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  padding-top: 24px;
  width: 100%;
}

.card-item {
  background: white;
  border-radius: 2px;
  padding: 16px;
  transition: transform 0.2s ease;
  height: 100%;

  &:hover {
    transform: translateY(-5px);
  }

  .carousel-card--hero {
    width: 100%;
    height: 162px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
  }
}

.product-home {
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  width: 50%;
  max-width: 60px;
  min-width: max-content;

  & > span {
    color: inherit;
  }

  &:hover {
    background-color: #fff !important;
    text-decoration: none;
  }
}

.home-project-list {
  &__traceability {
    background: rgba(190, 143, 31, 0.20);
    color: #BE8F1F;
    @extend .product-home;

    &:hover {
      border-color: #BE8F1F !important;
      color: #BE8F1F !important;
    }
  }

  &__lca {
    background: rgba(119, 63, 15, 0.20);
    color: #773F0F;
    @extend .product-home;

    &:hover {
      border-color: #773F0F !important;
      color: #773F0F !important;
    }
  }
}

.pointer-event--none {
  pointer-events: none;
}

.text-yellow-hover:hover {
  color: #BE8F1F !important;
}

.cursor-not-allow {
  cursor: not-allowed !important;
}

.non-click {
  pointer-events: none;
  & * {
    color: $dark-40 !important;
  }
}

// .box-shadow {
//   box-shadow: 0px 4px 8px rgba(214, 211, 211, 0.48);
//   -moz-box-shadow: 0px 4px 8px rgba(214, 211, 211, 0.48);
//   -webkit-box-shadow: 0px 4px 8px rgba(214, 211, 211, 0.48);
// }

.border {
  border: 1px solid rgba(0,0,0,.125);
}

.bg-gray-00 {
  background-color: #F7F7F8;
}

.border-radius-10 {
  border-radius: 30px;
}

.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel {
  display: flex;
  transition: transform 0.3s ease;
}

.carousel-article {
  flex: 0 0 auto;
  max-width: 341px;
  max-height: 240px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin: 0px 10px;

  .carousel-card--hero {
    width: 309px;
    height: 162px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.carousel-article.active {
  flex: 0 0 auto;
}

.control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: #333;
  cursor: pointer;
  padding: 5px;
  z-index: 1;
}

.prev {
  left: -10px;
}

.next {
  right: -10px;
}

.tag-cell {

  &--completed {
    background-color: #52780033;
    color: #527800;
  }

  &--collecting-data {
    background-color: #E0A75E33;
    color: #966026;
  }

  &--validating-data {
    background-color: #F0F0F2;
    color: #1C1C30;
  }

  &--pending {
    color: $dark-40;
  }
}

.w-30 {
  width: 30%;
}

.custom-spinner {
  .spinner-bcome {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.poiner-event--normal {
  pointer-events: all !important;
}

.w-max-content {
  width: max-content;
}

.open-sidebar {
  .integration-container {
    margin-left: 30px;
  }
}

.api-integration-modal {
  &__header {
    padding: 0px 24px 0px 0px;
    gap: 5px;
    position: relative;

    & > h5 {
      padding: 10px;
      margin: 0;
      cursor: pointer;
    }

    .active {
      font-weight: 500;
      border-bottom: 1px solid black;
      z-index: 10;
    }

    &::before {
      position: absolute;
      content: " ";
      width: calc(100% + 40px);
      height: 1px;
      background-color: #D6D6D7;
      left: -20px;
      top: 0;
    }
    &::after {
      position: absolute;
      content: " ";
      width: calc(100% + 40px);
      height: 1px;
      background-color: #D6D6D7;
      left: -20px;
      bottom: 0;
    }
  }
}

.favorite {
  border: none;
  background-color: transparent;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover path {
    stroke: #1C1C30;
  }
}

.text-decoration-hover {
  &:hover {
    text-decoration: underline;
  }
}