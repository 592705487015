@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?lm9f93');
  src:  url('fonts/icomoon.eot?lm9f93#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lm9f93') format('truetype'),
    url('fonts/icomoon.woff?lm9f93') format('woff'),
    url('fonts/icomoon.svg?lm9f93#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-water-year:before {
  content: "\e905";
}
.icon-icon-index-e:before {
  content: "\e900";
  color: #B44D49;
}
.icon-icon-index-d:before {
  content: "\e901";
  color: #E0A75E;
}
.icon-icon-index-c:before {
  content: "\e902";
  color: #F0D05F;
}
.icon-icon-index-b:before {
  content: "\e903";
  color: #9A9753;
}
.icon-icon-index-a:before {
  content: "\e904";
  color: #527800;
}
.icon-icon-arrows-switch:before {
  content: "\e90a";
}
.icon-icon-sidebar-button:before {
  content: "\e9b1";
}
.icon-icon-pin:before {
  content: "\e90b";
}
.icon-icon-animal-group:before {
  content: "\e90c";
}
.icon-icon-artificial-group:before {
  content: "\e90d";
}
.icon-icon-vegetal-group:before {
  content: "\e91b";
}
.icon-icon-polymer-group:before {
  content: "\e913";
}
.icon-icon-non-organic-group:before {
  content: "\e90e";
}
.icon-icon-profit:before {
  content: "\e914";
}
.icon-icon-map:before {
  content: "\e918";
}
.icon-icon-durability:before {
  content: "\e90f";
}
.icon-icon-recyclability:before {
  content: "\e915";
}
.icon-icon-recycled-cotton:before {
  content: "\e916";
}
.icon-icon-star:before {
  content: "\e917";
}
.icon-icon-location:before {
  content: "\e91e";
}
.icon-icon-dashboard:before {
  content: "\e91f";
}
.icon-icon-bar-selected:before {
  content: "\e989";
  color: #2c2c4f;
}
.icon-icon-bar:before {
  content: "\e98e";
}
.icon-icon-info:before {
  content: "\e920";
}
.icon-icon-warning:before {
  content: "\e921";
}
.icon-icon-success:before {
  content: "\e987";
}
.icon-icon-bg-notification:before {
  content: "\e922";
}
.icon-icon-solution:before {
  content: "\e923";
}
.icon-icon-u-selected:before {
  content: "\e924";
  color: #2c2c4f;
}
.icon-icon-u-selected-all:before {
  content: "\e925";
  color: #b0b0bf;
}
.icon-icon-p-selected:before {
  content: "\e926";
  color: #2c2c4f;
}
.icon-icon-p-selected-all:before {
  content: "\e927";
  color: #b0b0bf;
}
.icon-icon-pe-selected:before {
  content: "\e928";
  color: #2c2c4f;
}
.icon-icon-pe-selected-all:before {
  content: "\e929";
  color: #b0b0bf;
}
.icon-icon-r-selected:before {
  content: "\e92a";
  color: #2c2c4f;
}
.icon-icon-r-selected-all:before {
  content: "\e92b";
  color: #b0b0bf;
}
.icon-icon-ue-selected:before {
  content: "\e92c";
  color: #2c2c4f;
}
.icon-icon-ue-selected-all:before {
  content: "\e92d";
  color: #b0b0bf;
}
.icon-icon-id-water:before {
  content: "\e92e";
  color: #015594;
}
.icon-icon-id-eutrophication:before {
  content: "\e92f";
  color: #887d36;
}
.icon-icon-id-global:before {
  content: "\e930";
}
.icon-icon-id-abiotic-depletion:before {
  content: "\e931";
  color: #1f4a53;
}
.icon-icon-id-articles:before {
  content: "\e932";
}
.icon-icon-id-raw-material:before {
  content: "\e933";
  color: #927F3F;
}
.icon-icon-id-material-process:before {
  content: "\e934";
  color: #CD90B3;
}
.icon-icon-id-material-wet-process:before {
  content: "\e935";
  color: #E4AF4C;
}
.icon-icon-id-article-wet-process:before {
  content: "\e936";
  color: #9f7070;
}
.icon-icon-id-manufacturing:before {
  content: "\e937";
  color: #DE6E56;
}
.icon-icon-id-packaging:before {
  content: "\e938";
  color: #5A2320;
}
.icon-icon-id-transport:before {
  content: "\e939";
  color: #FB8C00;
}
.icon-icon-id-retail:before {
  content: "\e910";
  color: #5a2320;
}
.icon-icon-id-use:before {
  content: "\e911";
  color: #91939f;
}
.icon-icon-id-end-life:before {
  content: "\e912";
  color: #728081;
}
.icon-icon-id-circularity:before {
  content: "\e9af";
  color: #c8b7af;
}
.icon-icon-id-traceability:before {
  content: "\e919";
  color: #be8f1f;
}
.icon-icon-id-planet:before {
  content: "\e91a";
  color: #773f0f;
}
.icon-icon-id-people:before {
  content: "\e91c";
}
.icon-icon-id-economy:before {
  content: "\e91d";
  color: #65578b;
}
.icon-icon-water:before {
  content: "\e94b";
}
.icon-icon-co2:before {
  content: "\e906";
}
.icon-icon-po4:before {
  content: "\e947";
}
.icon-icon-abiotic:before {
  content: "\e93a";
}
.icon-icon-articles:before {
  content: "\e96e";
}
.icon-icon-raw-material:before {
  content: "\e969";
}
.icon-icon-material-process:before {
  content: "\e96a";
}
.icon-icon-wetprocess-material:before {
  content: "\e96b";
}
.icon-icon-wetprocess-article:before {
  content: "\e98c";
}
.icon-icon-manufacturing:before {
  content: "\e97c";
}
.icon-icon-packaging:before {
  content: "\e98f";
}
.icon-icon-suppliers:before {
  content: "\e950";
}
.icon-icon-retail:before {
  content: "\e9b0";
}
.icon-icon-use:before {
  content: "\e93b";
}
.icon-icon-end-life:before {
  content: "\e93c";
}
.icon-icon-process:before {
  content: "\e948";
}
.icon-icon-distance:before {
  content: "\e945";
}
.icon-icon-certificate:before {
  content: "\e943";
}
.icon-icon-people:before {
  content: "\e97b";
}
.icon-icon-home:before {
  content: "\e93d";
}
.icon-icon-calculator:before {
  content: "\e93e";
}
.icon-icon-metrics:before {
  content: "\e93f";
}
.icon-icon-performance:before {
  content: "\e940";
}
.icon-icon-diagnosis:before {
  content: "\e941";
}
.icon-icon-digitization:before {
  content: "\e942";
}
.icon-icon-planet:before {
  content: "\e946";
}
.icon-icon-benchmark:before {
  content: "\e949";
}
.icon-icon-ecommerce:before {
  content: "\e94a";
}
.icon-icon-smart-labels:before {
  content: "\e94c";
}
.icon-icon-grid:before {
  content: "\e94d";
}
.icon-icon-calculation:before {
  content: "\e94e";
}
.icon-icon-economics:before {
  content: "\e951";
}
.icon-icon-circularity:before {
  content: "\e94f";
}
.icon-icon-cotton:before {
  content: "\e952";
}
.icon-icon-graphics:before {
  content: "\e953";
}
.icon-icon-benchmarking:before {
  content: "\e954";
}
.icon-icon-index:before {
  content: "\e955";
}
.icon-icon-traceability:before {
  content: "\e956";
}
.icon-icon-academy:before {
  content: "\e957";
}
.icon-icon-save:before {
  content: "\e958";
}
.icon-icon-columns:before {
  content: "\e959";
}
.icon-icon-line-thermometer:before {
  content: "\e95a";
}
.icon-icon-line-locked:before {
  content: "\e95b";
}
.icon-icon-table:before {
  content: "\e95c";
}
.icon-icon-methodology:before {
  content: "\e95d";
}
.icon-icon-savings:before {
  content: "\e95e";
}
.icon-icon-consulting:before {
  content: "\e95f";
}
.icon-icon-padlock:before {
  content: "\e960";
}
.icon-icon-bags:before {
  content: "\e961";
}
.icon-icon-waste:before {
  content: "\e962";
}
.icon-icon-download:before {
  content: "\e963";
}
.icon-icon-share:before {
  content: "\e964";
}
.icon-icon-trash-empty:before {
  content: "\e965";
}
.icon-icon-trash:before {
  content: "\e966";
}
.icon-icon-search-add:before {
  content: "\e967";
}
.icon-icon-search:before {
  content: "\e968";
}
.icon-icon-projects:before {
  content: "\e96c";
}
.icon-icon-notification:before {
  content: "\e96d";
}
.icon-icon-menu:before {
  content: "\e96f";
}
.icon-icon-materials:before {
  content: "\e970";
}
.icon-icon-lock:before {
  content: "\e971";
}
.icon-icon-help-circle:before {
  content: "\e972";
}
.icon-icon-filter:before {
  content: "\e973";
}
.icon-icon-eye-off:before {
  content: "\e974";
}
.icon-icon-eye:before {
  content: "\e975";
}
.icon-icon-edit:before {
  content: "\e976";
}
.icon-icon-close:before {
  content: "\e977";
}
.icon-icon-arrow-filter-high:before {
  content: "\e978";
}
.icon-icon-arrow-filter-low:before {
  content: "\e979";
}
.icon-icon-chevron-asc:before {
  content: "\e97a";
}
.icon-icon-chevron-desc:before {
  content: "\e97d";
}
.icon-icon-order:before {
  content: "\e97e";
}
.icon-icon-chevron-down:before {
  content: "\e97f";
  font-size: 0.9em;
  -webkit-text-stroke-width: 0.15px;
  text-stroke-width: 0.15px;
}
.icon-icon-chevron-left:before {
  content: "\e980";
}
.icon-icon-chevron-right:before {
  content: "\e981";
}
.icon-icon-chevron-up:before {
  content: "\e982";
}
.icon-icon-chevron-doble-left:before {
  content: "\e983";
}
.icon-icon-chevron-doble-right:before {
  content: "\e984";
}
.icon-icon-code:before {
  content: "\e985";
}
.icon-icon-tick:before {
  content: "\e986";
}
.icon-icon-check-select:before {
  content: "\e988";
}
.icon-icon-views:before {
  content: "\e98a";
}
.icon-icon-copy:before {
  content: "\e98b";
}
.icon-icon-new-window:before {
  content: "\e98d";
}
.icon-icon-checkbox:before {
  content: "\e990";
}
.icon-icon-checkbox-selected:before {
  content: "\e991";
}
.icon-icon-button-filters:before {
  content: "\e992";
}
.icon-icon-camera:before {
  content: "\e993";
}
.icon-icon-at-sign:before {
  content: "\e994";
}
.icon-icon-arrow-circle-up:before {
  content: "\e995";
}
.icon-icon-arrow-circle-right:before {
  content: "\e996";
}
.icon-icon-arrow-circle-left:before {
  content: "\e997";
}
.icon-icon-arrow-circle-down:before {
  content: "\e998";
}
.icon-icon-add:before {
  content: "\e999";
}
.icon-icon-car:before {
  content: "\e99a";
}
.icon-icon-car-not-travelled:before {
  content: "\e99b";
}
.icon-icon-plane:before {
  content: "\e9ad";
}
.icon-icon-plane-not-travelled:before {
  content: "\e9ae";
}
.icon-icon-not-planted-trees:before {
  content: "\e9b4";
}
.icon-icon-planted-trees:before {
  content: "\e99c";
}
.icon-icon-not-trees:before {
  content: "\e9b5";
}
.icon-icon-trees:before {
  content: "\e99d";
}
.icon-icon-electricity:before {
  content: "\e99e";
}
.icon-icon-bulbs:before {
  content: "\e99f";
}
.icon-icon-tomatoes:before {
  content: "\e9a0";
}
.icon-icon-hairdryer:before {
  content: "\e9a1";
}
.icon-icon-battery:before {
  content: "\e9a2";
}
.icon-icon-plastic-bag:before {
  content: "\e9a3";
}
.icon-icon-washer:before {
  content: "\e9a4";
}
.icon-icon-detergent:before {
  content: "\e9a5";
}
.icon-icon-not-fish-saved:before {
  content: "\e9b6";
}
.icon-icon-fish-saved:before {
  content: "\e9a6";
}
.icon-icon-not-oxigen:before {
  content: "\e9b7";
}
.icon-icon-oxigen:before {
  content: "\e9a7";
}
.icon-icon-socks:before {
  content: "\e9a8";
}
.icon-icon-not-glasses:before {
  content: "\e9b8";
}
.icon-icon-glasses:before {
  content: "\e9a9";
}
.icon-icon-pool:before {
  content: "\e9ac";
}
.icon-icon-bottle:before {
  content: "\e9b2";
}
.icon-icon-learning-center:before {
  content: "\e9b3";
}
.icon-icon-pdf:before {
  content: "\e9b9";
}
.icon-icon-zip:before {
  content: "\e9ba";
}
.icon-icon-u:before {
  content: "\e9bb";
}
.icon-icon-ui:before {
  content: "\e9bc";
}
.icon-icon-ue:before {
  content: "\e9bd";
}
.icon-icon-pi:before {
  content: "\e9be";
}
.icon-icon-pe:before {
  content: "\e9bf";
}
.icon-icon-p:before {
  content: "\e9c0";
}
.icon-icon-r:before {
  content: "\e9c1";
}
.icon-bcome-spinner:before {
  content: "\e9c2";
}
.icon-icon-not-bulb:before {
  content: "\e900";
}
