.sub-menu{
    transition: all 0.5s ease-in;
    overflow: hidden;
    opacity: 0;
    padding-top: 0.5rem;
}
.opened{
    overflow: visible;
    opacity: 1;
}

.scale-08{
    transform: scale(0.8);
}