.tag {
    position: absolute;
    right: 20px;
    border-radius: 20px;
    background-color: #91939F;
    padding: 1px 10px;
    color: white;
    font-size: 12px;
    height: 20px;
    text-align: center;
}

.r-10 {
    right: 10px;
}