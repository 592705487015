
.custom-input-select {
    .form-control {
        .placeholder {
            color: #ACACB2;
            opacity: 1;
            font-family: 'SFProTextRegular';
            font-size: 14px;
        } 

        &.open {
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;

            &.top {
                border-top-left-radius: 0px !important;
                border-top-right-radius: 0px !important;

                border-bottom-left-radius: 10px !important;
                border-bottom-right-radius: 10px !important;
            }
        }

        }

        .value {
            color: black;
        }


        &.open {
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;

            &.top {
                border-top-left-radius: 0px !important;
                border-top-right-radius: 0px !important;

                border-bottom-left-radius: 10px !important;
                border-bottom-right-radius: 10px !important;
            }
        }

        & .custom-section {
            width: 40px;
            height: 29px !important; }

    }
    .option-selector {
        position: absolute;
        background-color: #FFFFFF;
        left: 0;
        right: 0;
        z-index: 9999;
        padding: 5px;
        overflow-y: auto;
        max-height: 200px;
        border: 1px solid #1C1C30;
        box-sizing: border-box;
        border-radius: 0px 0px 10px 10px;
        border-top: 0px !important;
        // margin-left: 15px;
        // margin-right: 15px;

        .option:hover {
            background-color: #F0F0F2;
            border-radius: 10px;
        }

       


        &.top {
            // top: -65px;
            border-top: 1px solid #1C1C30 !important;
            border-radius: 10px 10px 0px 0px !important;
        }
    }
    
    .custom-section-dropdown {
        width: 40px;
    }


.custom-input-select-disabled{
    opacity: 0.5;
    cursor: not-allowed;
}