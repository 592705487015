@import '../../../styles/app/common/variables.scss';

.tooltips {
    border: none !important;
    background-color: $gray-4 !important;
    border-radius: 8px !important;
    box-shadow:  0px 6px 6px rgba(214, 211, 211, 0.4) !important;
    color: $dark-60 !important;
    font-size: 12px !important;
    padding: 8px;
    font-family: 'SFProTextRegular';
    max-width: none;
}

.arrow-tooltip::before {
  color: $gray-4 !important;
  border-bottom-color: $gray-4 !important;
  border-top-color: $gray-4 !important;
}

.popover .arrow::after {
  border-bottom-color: $gray-4 !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltipCustom {
    position: relative;
  }

  /* Tooltip text */
  .tooltipCustom .tooltiptext {
    border-radius: 10px !important;
    box-shadow: 0px 6px 6px rgba(214, 211, 211, 0.4);
    color: $dark-60 !important;
    font-family: 'SFProTextRegular';
    font-size: 10px;
    min-height: 24px;
    padding: 6px 6px;
    position: absolute;
    text-align: left;
    visibility: hidden;
    z-index: 2;
  }

  .tooltip-dashboard {
    background-color: $gray-4 !important;
    border-radius: 8px !important;
    box-shadow:  0px 6px 6px rgba(214, 211, 211, 0.4) !important;
    color: $dark-60 !important;
    font-size: 12px !important;
    font-family: 'SFProTextRegular';
    min-height: 24px;
    padding: 6px 8px;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltipCustom:hover .tooltiptext {
    visibility: visible;
    background-color: $gray-4 !important;
    border-radius: 8px !important;
    box-shadow:  0px 6px 6px rgba(214, 211, 211, 0.4) !important;
    color: $dark-60 !important;
    font-size: 12px !important;
  }

  .tooltiptext-left {
      left: 50px;
  }


// 🔥 new tooltips
.tooltip-container {
  position: relative;
  display: inline-block;

  .tooltip {
    visibility: hidden;
    background-color: $gray-4 !important;
    border-radius: 8px !important;
    box-shadow:  0px 6px 6px rgba(214, 211, 211, 0.4) !important;
    color: $dark-60 !important;
    font-size: 12px !important;
    padding: 8px;
    font-family: 'SFProTextRegular';
    padding: 5px;
    position: fixed;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    width: max-content;
    max-width: 300px;
  }

}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
