input[type="radio"] {
	display: grid;
	place-content: center;
	appearance: none;
	background-color: #fff;
	margin: 3px 0 0 0;
	color: #75747F;
	cursor: pointer;
	width: 17px;
	height: 17px;
	border: 1px solid #75747F;
	border-radius: 50%;
	transform: translateY(-0.075em);
	gap: 0.5em;
}


input[type="radio"]::before {
	content: "";
	width: 8px;
	height: 8px;
	color: #1C1C30;
	background-color: #1C1C30;
	border-radius: 50%;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
}
  
input[type="radio"]:checked {
	border: 1.5px solid #1C1C30 !important;
}

input[type="radio"]:checked::before {
	transform: scale(1);
}


.input-radio-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}