.app-container {
    display: flex;
    padding: 0 10px;
}

.app-container__sidebar {
    width: 14rem;
    position: relative;
    z-index: 0;
    margin-right: 10px;
}

.app-container__main {
    /* Take the remaining width */
    flex: 1;
    display: flex;
    position:relative;
    flex-direction: column;

    /* Make it scrollable */
    overflow: visible;
}
    