@import './../../../../styles/app/common/variables.scss';

.modal-base {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
  }
  
  .modal-base-main {
    position:fixed;
    background: #FBFBFC;
    width: 440px;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 8px;
    box-shadow: 0px 6px 18px rgba(33, 33, 33, 0.3);
  }

  .product-info-size {
    width: 459px;
    height: 692px;
    padding: 30px;
  }

  .product-info-section {
    border-bottom: #F0F0F2 1px solid;
  }

  .backdrop-color {
    backdrop-filter: blur(1px);
    background-color: rgba(117, 116, 127, 0.4) !important; //rgb for dark-40
    backdrop-filter: blur(1px);
  }

  .modal-base input {
    border: 1px solid #75747F;
    border-radius: 10px;
    background-color: unset;
    padding: 8px;
  }

  .modal-base .errMsg {
    border: 1px solid #B44D49 !important;
    color: $dark-100 !important;
  }

  .err-msg {
    font-size: 12px;
    color: #B44D49;
    font-family: 'SFProTextRegular';
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  .modal-base .icon-icon-close:hover {
    cursor: pointer;
  }

  .product-info-item:hover div,
  .product-info-item:hover span {
    color: #494853 !important;
  }

  input::placeholder {
    color: #b5b5b5;
    font-family: 'SFProTextRegular'
  }

  .form-control.is-invalid {
    background-color: inherit !important;
    background-image: none !important;
    border: 1px solid #B44D49;
    color: $dark-100
  }
  