.configuration-menu {
	width: 100%;
	transition: all 0.5s ease;
	overflow: hidden;
	overflow-y: scroll;
	padding-left: 0px !important;
    padding-right: 0px !important;


    *{
        box-sizing: border-box;
    }
}
.css-textarea {
	height: 200px;
	max-height: 200px;
	resize: none;
   font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
   font-weight: 300;
   font-size: 12px;
   background: #F0F0F2;
   border-radius: 10px;
   border-color: #777777;
   margin: 0 0.75rem 0.5rem;
   padding: 0.5rem;
}

