/* ========================================================================
     Component: animate
 ========================================================================== */

@charset "UTF-8";

// New Animations
// -----------------------------------
@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

@keyframes fadeOutUpShort {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort;
}

@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    -ms-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort;
}

@-webkit-keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    -ms-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }

  50% {
    opacity: 1;
  }
}

.zoomInShort {
  -webkit-animation-name: zoomInShort;
  animation-name: zoomInShort;
}

@-webkit-keyframes zoomBack {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5);
  }

  100% {
    opacity: 0;
  }
}

@keyframes zoomBack {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    -ms-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5);
  }

  100% {
    opacity: 0;
  }
}

.zoomBack {
  -webkit-animation-name: zoomBack;
  animation-name: zoomBack;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    -ms-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}


.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.fadeInLeft {
  -webkit-animation: fadeInLeft 1s ease;
  animation: fadeInLeft 1s ease;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-60px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.fadeInRight {
  -webkit-animation: fadeInRight 1s ease;
  animation: fadeInRight 1s ease;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(80px);
  }
}

// Timing functions
// -----------------------------------
// Cubic
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);

// Circ
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// Expo
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

// Quad
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

// Quart
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);

// Quint
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);

// Sine
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

// Back
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$animate-duration: 0.5s;

.rag-fadeIn-enter {
  animation: fadeIn $animate-duration;
}
.rag-fadeIn-exit {
  display: none;
}

.rag-fadeInRight-enter {
  animation: fadeInRight $animate-duration;
}
.rag-fadeInRight-exit {
  display: none;
}

.rag-fadeInLeft-enter {
  animation: fadeInLeft $animate-duration;
}
.rag-fadeInLeft-exit {
  display: none;
}
