.traceability {
    padding: 0px 10px 0px 20px !important;

    & > span, div {
        flex-grow: 2;
    }

    &-High {
        & > span, div {
            color: #527800;
        }
    }

    &-Medium {
        & > span, div {
            color: #E0A75E;
        }
    }

    &-Low {
        & > span, div {
            color: #B44D49;
        }
    }
}

.metrics {
    padding: 0px 10px 0px 20px !important;

    & > span, div {
        flex-grow: 2;
    }

    &-high {
        & > span, div {
            color: #527800;
        }
    }

    &-medium {
        & > span, div {
            color: #E0A75E;
        }
    }

    &-low {
        & > span, div {
            color: #B44D49;
        }
    }
}

.traceability-status {
    border-radius: 10px;
    border: 1px;
    border-style: solid;
    width: max-content;
    height: 18px;
    font-size: 10px;
    flex-grow: 0;
}