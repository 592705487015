@import '../../../styles/app/common/variables.scss';

/* Modal */
.backdrop {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  display: grid;
  place-items: center;
  justify-items: end;
  z-index: 8;
}
.modal-div i {
  cursor: pointer;
}

.modal-backdrop {
  opacity: 0 !important;
}


.modal-div {
  background: $modal-content-bg; //$white-100;
  color: $dark-100;
  display: inline;
  min-width: 430px;
  max-width: 430px;
  padding-bottom: 70px;
  height: 100vh;
  outline: none;
  // padding: 10px;
  // align-items: right;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 9;
  overflow: scroll;
  overflow-x: hidden;
  border-radius: 20px 0 0 20px;

  // display: flex;
  // flex-direction: column;
}

.modal-lateral-header {
  border-bottom: 0.5px solid rgb(172, 172, 178);
  > h5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    > i {
      font-size: 20px;
      cursor: pointer;
      color: $dark-100;
      &.loading {
        opacity: 0.5;
        cursor: initial;
      }
    }
  }
  > button {
    display: none;
    color: $dark-60;
  }
  .modal-title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #1C1C30;
    font-family: 'SFProTextMedium';
  }
}

.selected-type-modal {
  background-color: $white-100
}

.modal-lateral-close {
  -webkit-mask: url('../../../styles/icons/icon-close.svg') no-repeat 50% 50%;
  mask: url('../../../styles/icons/icon-close.svg') no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: $dark-60;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

/* width scroll modal */
// .modal-div ::-webkit-scrollbar {
//   width: 10px;
// }

/* Track scroll modal */
.modal-div ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle scroll modal*/
.modal-div ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover scroll modal */
.modal-div ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-button-close {
  width: 100px;
}

.deselect-columns:hover {
  color: #494853;
  cursor: pointer;
  text-decoration: underline;
}

.deselect-columns:active {
  color: #1C1C30;
  text-decoration: underline;
  font-weight: 500;
}

.title-dropdown-container:hover span {
  color: #494853;
}

.icon-dataType:hover {
  color: #494853 !important;
}
