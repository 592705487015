/* Container for the input and label */
.floating-label-container {
	position: relative;
	width: 100%;
}

/* The input field */
.floating-input {
	width: 100%;
	padding: 8px 0;
	border: none;
	border-bottom: 1px solid #1C1C30;
	font-size: 14px;
	outline: none;
	background: none;
	position: relative;
	z-index: 1;
}

/* Label inside the input */
.floating-label {
	position: absolute;
	left: 0px;
	top: 50%;
	transform: translateY(-45%);
	transition: all 0.2s ease;
	font-size: 14px;
	color: #1C1C30;
	pointer-events: none;
}

/* When the input is focused or has value */
.floating-input:focus + .floating-label,
.floating-input.has-value + .floating-label {
	top: 0;
	transform: translateY(-100%);
}

/* Add a focus border color */
.floating-input:focus {
	border-color: #1C1C30;
}

.floating-label-container.invalid {
	input {
		border-color: #B44D49;
		color: #B44D49;
	}
	span, label {
		color: #B44D49;
	}
}

.floating-label-container {
	span:not(.error-message) {
		font-size: 14px;
		position: absolute;
		right: 3px;
		top: 50%;
		transform: translateY(-45%);
		z-index: 10;
		cursor: pointer;
	}
}

.error-message {
	color: #B44D49;
	font-size: 14px;
	display: flex;
}