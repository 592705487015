
.tabs {
    .tab-selector {
        .selected {
            background-color: white;
            border-radius: 10px;
            color: #1C1C30;
            font-size: 16px;
            line-height: 26px;
            box-shadow: 0px 4px 8px #D6D3D3;
        }
        .number {
            background: #E0A75E;
            color: white; 
            border-radius: 50px;
            width: 18px;
            height: 18px;
            font-size: 10px !important;
            padding-left: 2px;
        }
    }
}