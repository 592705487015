.chart-container {
    display: flex;
    padding: 16px 16px 32px 16px !important;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 2px;
    background: var(--New-Palette-White, #fff);
    width: 100%;
    height: 100%;
}