
    *,
    *::before,
    *::after {
      box-sizing: border-box;
      backface-visibility: hidden;
    }

    .toggle-label,.toggle-label::after,.toggle-label::before{
        transition: 350ms all ease;
    }

    .toggle-input {
      display: none;
    }



    /*Button is :CHECKED*/

    .toggle-input:checked ~ .toggle-div {
      background: #1C1C30;
      box-shadow: 0 0 2px #1C1C30;
    }

    .toggle-input:checked ~ .toggle-div .toggle-label {
      left: 10.5px;

    }


    /*shared*/

    .toggle-div,
    .toggle-label {
      border-radius: 100px;
    }


    /*'un':checked state*/

    .toggle-div {
      height: 16px;
      width: 28px;
      background: #ACACB2;
      position: relative;
      box-shadow: 0 0 2px #ACACB2;
    }

    .toggle-label {
      height: 13px;
      width: 16px;
      background: #F9F9F9;
      position: absolute;
      top: 1.5px;
      left: 2px;
      cursor: pointer;
    }

    .toggle-label::before {
      content: '';
      height: 9px;
      width: 1px;
      position: absolute;
      transform: rotate(-45deg);
      left: 8px;
      top: 2px;
    //   top: calc(50% - 3.5px);
    //   left: calc(50% - 0.5px);
    //   transform: rotate(45deg);
    }

    .toggle-label::after {
      content: '';
      height: 1px;
      width: 9px;
      position: absolute;
      transform: rotate(-45deg);
      left: 4px;
      top: 6px;
    }

    .toggle-label::before,
    .toggle-label::after{
      background: #ACACB2;
      border-radius: 2px;
    }

    /* pesduo class on toggle */

    .toggle-input:checked ~ .toggle-div .toggle-label::before{
      height: 5px;
      top: 5px;
      left: 5px;
      background: #1C1C30;
    }
    .toggle-input:checked ~ .toggle-div .toggle-label::after{
      width: 8px;
      top: 6px;
      left: 6px;
      background: #1C1C30;
    }

    .toggle-disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }

    .toggle-label-disabled, .toggle-label-disabled::before, .toggle-label-disabled::after{
        cursor: not-allowed !important;
    }