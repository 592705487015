.product-traceability-container {
    gap: 8px;
	border-left-style: dotted;
	border-left-width: 1.5px;

    &::before {
        content: ' ';
        width: 10px;
        height: 10px;
        background-color: rgb(240, 240, 242);
        top: 0px;
        left: -5px;
        position: absolute;
    }

    &::after {
        content: ' ';
        width: 10px;
        height: 27px;
        background-color: rgb(240, 240, 242);
        bottom: 0px;
        left: -5px;
        position: absolute;
    }

    .item-container {
        width: 8px;
		height: 8px;
		background: #1C1C30;
		border-radius: 50%;
		left: -12.5px;
		top: 7px;
    }
}

.smart-label__footer {
    padding: 4px;
    border-radius: 30px;
    width: max-content;

    & > span {
        padding: 6px 12px 6px 12px;
        border-radius: inherit;
        min-width: 100px;
        text-align: center;
    }
}

.text-decoration-underline {
    text-decoration: underline;
}