#login-form {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: $dark;
  width: 80%;
  height: 100%;

  form {
    gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: self-start;
  }

  @include media-breakpoint-up(md) {
    width: 450px;
  }

  h1, h2, h3 {
    font-size: 12px;
    color: #1C1C30;
    font-weight: 400;
  }

  h3 {
    color: #B44D49;
  }

  .section-title {
    font-size: 3em;
    margin-bottom: 0.5em;
    text-align: center;
  }

  #form-container {
    border-radius: 10px;
    margin: auto;
    height: max-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .form-header {
    width: 100px;
    height: 100px;
    margin: auto;
    border: 1px solid$primary;
    border-radius: 50%;
    position: relative;
    top: 20px;
    background:$primary;
    z-index: -1;
    text-align: center;

    .icon {
      color: white;
      top: 20%;
      font-size: 4em;
    }
  }

  .input-group-text.oi-person {
    height: 38px;
    margin-top: -1px;
    padding-top: 9px;
    width: 46px;
  }

  .submit-container {
    text-align: right;
    padding: 0;
  }

  .forgot-password-container {
    margin-top: 5px;
    font-size: 1.1em;
    padding-left: 8px;
  }

  .invalid-feedback {
    padding-left: 45px;
    font-size: 1em;
  }
}

.checkbox label {
  user-select: none;
}

.modal-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 100%;

  & div {
    flex-basis: 100%;
  }
}
.swal2-title {
  display: flex;
  word-break: break-word;
  white-space: normal;
}
.swal2-html-container {
  white-space: normal;
}

.logo {
  max-width: 150px;
  position: absolute;
  top: -75px;
  right: -75px;
  color: $dark-100;
}

.login-title {
  font-family: $font-sfpro-medium;
  font-size: 25px;
  color: $dark-100;
}

.login-input {
  background-color: $gray-1;
  border-color: $dark-60;
  color: $dark-80;
  font-family: $font-sfpro-medium;

  &:focus {
    background-color: $gray-1;
    border-color: $dark-60;
    color: $dark-80;
  }

  &::placeholder {
    color: $dark-60;
    font-family: $font-sfpro-regular;
  }
}

.forgot-password {
  cursor: pointer;
  color: $dark-100;
  font-family: $font-sfpro-regular;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.login-input-title {
  color: $dark-100;
  font-family: $font-sfpro-medium;
}

.iconClassName {
  width: 20px;
  height: 20px;
  background-color: $dark-60;

  &.atSign {
    -webkit-mask: url('../../../styles/icons/icon-at-sign.svg') no-repeat 50% 50%;
    mask: url('../../../styles/icons/icon-at-sign.svg') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
  }

  &.lock {
    -webkit-mask: url('../../../styles/icons/icon-lock.svg') no-repeat 50% 50%;
    mask: url('../../../styles/icons/icon-lock.svg') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
  }
}