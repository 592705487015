@import '../../../styles/app/common/variables.scss';

.feedback-layer.modal-feedback-layer {
  top: 0;
  left: 0;
}

.custom-modal-header, .modal-body, .modal-footer {
  background-color: $modal-content-bg;
  //color: $dark-80;
}

.custom-modal-header {
  > h5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    > i {
      font-size: 1.5rem;
      cursor: pointer;
      color: $dark-80;
      &.loading {
        opacity: 0.5;
        cursor: initial;
      }
    }
  }
  > button {
    display: none;
  }
  .modal-title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #1C1C30;
    font-family: 'SFProTextMedium';
  }
}

.close {
  -webkit-mask: url('../../../styles/icons/icon-close.svg') no-repeat 50% 50%;
  mask: url('../../../styles/icons/icon-close.svg') no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: $dark-80;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.wide-modal {
  max-width: 80%;
}


// @media screen and (max-width: 1280px) {
//   .page-selector-modal {
//     max-width: 90%;
//   }
// }
