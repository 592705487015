@import '../../bootstrap/functions';
@import '../../bootstrap/mixins';
@import '../../bootstrap/variables';

// Font variables
$font-futura-light: 'FuturaPTWebLight';
$font-futura-medium: 'FuturaPTWebMedium';
$font-futura-book: 'FuturaPTWebBook';
$font-sfpro-light: 'SFProTextLight';
$font-sfpro-medium: 'SFProTextMedium';
$font-sfpro-regular: 'SFProTextRegular';
$font-helvetica: 'Helvetica';

// OLD Web Colors
$white-brand: #eae5dc;
$white-48: #f4f1ed;
$white-84: #faf8f6;

$black-12: #d6d3d3;
$black-24: #c2c0c0;
$black-36: #b5b5b5;
$black-48: #ACACB2;
$black-60: #696980;
$black-72: #494853;
$black-84: #3B3B68;
$black-100: #2C2C4F;

$green: #9a9753;
$brown: #8d6e36;
// $yellow: #e0a75e;
$red: #b44d49;
$invalid: #ffdddb;

// NEW BCOME PALETE - 2022
$gray-1: #F0F0F2;
$gray-2: #FBFBFC;
$gray-3: #F9F9F9;
$gray-4: #F6F6F7;
$white-100: #FFFFFF;

$blue-metal: #56595A;
$blue-metal-30: #C6C7C8;
$blue-metal-10: #DDD;

$dark-100: #1C1C30;
$dark-80: #494853;
$dark-60: #706F7A;
$dark-40: #ACACB2;

$yellow: #CCAA5B;


// Cutoms Gray colors for theme
$gray: #dde6e9;
$gray-lighter: #edf1f2;
$gray-light: #e4eaec;
$gray-dark: #3a3a3a;
$gray-darker: #2f2f2f;

// Custom brand colors for theme
$inverse: #131e26;
$purple: #7266ba;
$pink: #f532e5;
$primary: #1C1C30;
$secondary: #9c815c;
$success: #9A9753; //27c24c;
$info: #fff; //23b7e5;
$warning: #E0A75E;
$warning: #08a816;
$danger: #B44D49; //f05050;
$dark: #3a3a3a;
// Custom color variants
$amountOfLight: 10%;
$primary-light: lighten($primary, $amountOfLight);
$success-light: lighten($success, $amountOfLight);
$info-light: lighten($info, $amountOfLight);
$warning-light: lighten($warning, $amountOfLight);
$danger-light: lighten($danger, $amountOfLight);
$inverse-light: lighten($inverse, $amountOfLight);
$green-light: lighten($green, $amountOfLight);
$pink-light: lighten($pink, $amountOfLight);
$purple-light: lighten($purple, $amountOfLight);
$yellow-light: lighten($yellow, $amountOfLight);
$primary-dark: darken($primary, $amountOfLight);
$success-dark: darken($success, $amountOfLight);
$info-dark: darken($info, $amountOfLight);
$warning-dark: darken($warning, $amountOfLight);
$danger-dark: darken($danger, $amountOfLight);
$inverse-dark: darken($inverse, $amountOfLight);
$green-dark: darken($green, $amountOfLight);
$pink-dark: darken($pink, $amountOfLight);
$purple-dark: darken($purple, $amountOfLight);
$yellow-dark: darken($yellow, $amountOfLight);
// Theme variables
// used for sidebars and content spacing
$navbar-height: 55px;
// Button secondary redefinition style for theme
$btn-primary-color: #fff;
$btn-primary-bg: #9c815c;
$btn-primary-bg-hover: #886c45;
$btn-primary-border: #eaeaea;
$btn-secondary-color: #333;
$btn-secondary-bg: #F9F9F9;
$btn-secondary-border: #1C1C30;
// Top Border color for custom default cards (.card-default)
$card-default-border-color: #cfdbe2;
$separator-color: #cfdbe2;
// ---------------------
// Utilities variables
// ---------------------
$text-alpha: rgba(255, 255, 255, 0.5);
$text-alpha-inverse: rgba(0, 0, 0, 0.5);
$font-size-base: 1rem;
$text-sm: $font-size-base * 0.74375;
$text-md: $font-size-base * 1.6625;
$text-lg: $font-size-base * 2.625;
$border-color: rgba(0, 0, 0, 0.12);
$border-color: #B5B5B5;
$wd-xxs: 60px;
$wd-xs: 90px;
$wd-sm: 150px;
$wd-sd: 200px; // sd == standard
$wd-md: 240px;
$wd-lg: 280px;
$wd-xl: 320px;
$wd-xxl: 360px;
$wd-wide: 100%;
$wd-auto: auto;
$wd-zero: 0;
// ---------------------
// Bootstrap 4 Variables Override
// ---------------------
// Color system
$colors: (
  'purple': $purple,
  'pink': $pink,
  'yellow': $yellow,
  'green': $green,
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'inverse': $inverse,
  'primary-light': $primary-light,
  'primary-dark': $primary-dark,
  'success-light': $success-light,
  'success-dark': $success-dark,
  'info-light': $info-light,
  'info-dark': $info-dark,
  'warning-light': $warning-light,
  'warning-dark': $warning-dark,
  'danger-light': $danger-light,
  'danger-dark': $danger-dark,
  'green-light': $green-light,
  'green-dark': $green-dark,
  'pink-light': $pink-light,
  'pink-dark': $pink-dark,
  'purple-light': $purple-light,
  'purple-dark': $purple-dark,
  'inverse-light': $inverse-light,
  'inverse-dark': $inverse-dark,
  'yellow-light': $yellow-light,
  'yellow-dark': $yellow-dark
);
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger
);
// Body
$body-bg: #f5f7fa;
$body-color: #1C1C30;
$border-radius: 0.25rem;
$border-radius-lg: 0.375rem;
$line-height-base: 1.528571429;
$headings-line-height: 1.1;
$text-muted: #909fa7;
// Tables
$table-accent-bg: #fafbfc;
$table-hover-bg: #fafbfc;
$table-border-color: #eee;
// Buttons + Forms
$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.625rem;
$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.25rem;
$input-height: 40px; // 35px
// Forms
$input-disabled-bg: $gray-lighter;
$input-border-color: $gray;
$input-focus-border-color:$primary;
$input-placeholder-color: #b7bac9;
$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;
$custom-control-indicator-active-bg: lighten($primary, 25%);
// Dropdowns
$dropdown-border-color: #e1e1e1;
// Navs
$nav-tabs-link-active-bg: #fff;
// Jumbotron
$jumbotron-bg: #fcfcfc;
// List group
$list-group-border-color: #e4eaec;
// Image thumbnails
$thumbnail-padding: 0.125rem;
// Breadcrumbs
$breadcrumb-bg: #fafafa;
$breadcrumb-divider-color: #c1c2c3;
$breadcrumb-active-color: $body-color;
//tootip
$tooltip-bg: #1C1C30;

// LAYOUT
$content-bg: #F9F9F9;
$aside-bg: $gray-2;
$corporative: #1C1C30;
// NAVBAR TOP
$nav-top-bg: $gray-2;
$nav-top-bg-start: $nav-top-bg;
$nav-top-bg-end: $info;
$nav-header-bg: transparent;
$nav-top-item: $gray-600;
$nav-top-item-active: darken($nav-top-bg, 20%);
// SIDEBAR
$sidebar-bg: $aside-bg;
$sidebar-item-color: $gray-600;
$sidebar-item-color-active: $corporative;
$sidebar-item-bg-active: darken($sidebar-bg, 1%);
$sidebar-icon-color: $gray-600;
$sidebar-icon-color-active: $corporative;
$sidebar-bullet-color: rgba(0, 0, 0, 0.25);
$sidebar-bullet-color-active: $sidebar-icon-color-active;
$sidebar-heading-color: #919da8;
$sidebar-label-color: $sidebar-item-color;
// OFFSIDEBAR
$offsidebar-bg: black;
$offsidebar-border-color: greyscale(darken($offsidebar-bg, 20%));
$offsidebar-color: $body-color;

//SHADOWS
$box-shadow-075: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow-150: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-175: 0 1rem 3rem rgba($black, 0.175) !default;

// NEW PALETTE 
$primary-100: #001D6C;
$colors-green: #927F3F;
$colors-pink: #CD90B3;
$colors-yellow: #E4AF4C;
$colors-coral: #DE6E56;
$colors-orange: #FB8C00;
$colors-burgundy: #5A2320;