.btn {
  padding: 0.66rem;
}

.btn-primary {
  color: $white-100;
  background-color: $dark-100; // #1C1C30
  border-color: $dark-100; // #1C1C30
  font-family: $font-sfpro-medium;
  font-size: 14px;
  &:hover {
    color: $white-100;
    background-color: $dark-60;
    border-color: $dark-60
  }
  &:active {
    background-color: $dark-80; // #494853
    border-color: $dark-80; // #494853
  }
  &:disabled {
    background-color: $blue-metal-30; // #C6C7C8
    border-color: $blue-metal-30; // #C6C7C8
    box-shadow: none;

    &:hover {
      box-shadow: none !important;
    }
  }
}

.btn-secondary {
  color: $dark-60 !important; // #75747F 
  border-color: $dark-60 !important; // #75747F 
  font-size: 14px;

  &:hover {
    color: $dark-100 !important; // #1C1C30
    border-color: $dark-100 !important; // #1C1C30
    background-color: transparent !important;
  }
  &:disabled {
    background-color: $dark-80; // #494853
    border-color: $dark-80; // #494853

    &:hover {
      box-shadow: none;
    }
  }
}

.btn-ghost {
  color: $dark-100 !important; 
  border-color: transparent; 
  font-size: 14px;

  &:hover {
    color: $dark-100 !important; 
    border-color: $dark-100;
    background-color: transparent !important;
  }
  &:disabled {
    background-color: $dark-80; 
    border-color: $dark-80; 

    &:hover {
      box-shadow: none;
    }
  }
}

.btn-tertiary {
  color: $dark-100; // #1C1C30
  background-color: transparent;
  border-color: $dark-40; // #ACACB2
  border: solid 1px;
  font-family: $font-sfpro-medium;
  font-size: 14px;
}

.btn-transparent {
  color: $dark-60; // #75747F 
  background-color: transparent;
  border-color: transparent;
  font-family: $font-sfpro-medium;
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    color: $dark-100; // #1C1C30
  }

  &:disabled {
    color:  $blue-metal-30; // #C6C7C8
    text-decoration: none !important;

    &:hover {
      color:  $blue-metal-30; // #C6C7C8
      text-decoration: none !important;
    }
  }

}

.btn-primary-alt {
  color: $white-100;
  background-color: $dark-100; // #1C1C30
  border-color: $dark-100; // #1C1C30
  font-family: $font-sfpro-regular;
  font-size: 14px;
  padding: 0 1.5rem !important;
  
  &:hover {
    color: $white-100;
    box-shadow: 0px 4px 8px rgba(214, 211, 211, 0.48) !important;
  }
  &:active {
    background-color: $dark-80; // #494853
    border-color: $dark-80; // #494853
  }
    &:disabled {
        background-color: $dark-80; // #494853
        border-color: $dark-80; // #494853
    }
}

.btn-secondary-alt {
  color: #FFFFFF !important;
  font-family: $font-sfpro-regular !important; 
  font-size: 14px !important; 
  background-color: $dark-40 !important; // #ACACB2
  border-color: $dark-40 !important; // #ACACB2

  &:hover {
    color: #FFFFFF !important;
    border-color: $dark-40 !important; // #ACACB2
    background-color: $dark-40 !important; // #ACACB2
  }
}
