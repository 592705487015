.icon-class {
  position: absolute;
  left: 6px;
  top: 9px;
  max-width: 20px;
  font-size: 22px;
}

.pl-30px {
  padding-left: 35px;
}
