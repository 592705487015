.table-item {
    &:hover {
        background-color: #F0F0F2 !important;
    }

    &.striped {
        // background-color: #F9F9F9;
        background-color: #FBFBFC;

    }
}

.table-item:hover .icon-eye-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 28px;
    background-color: rgba(172, 172, 178, 0.4) !important; //rgb for dark-40
}

.table-item:hover .icon-icon-eye{
    color: #FFFFFF !important;
}

.table-item:hover .article-info {
    background-color: #F0F0F2 !important;
}

.table-item:hover .progress {
    background:#F9F9F9
}

.ask-update {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: center;
    gap: 3px;
    color: #706F7A;

    img {
        width: 15px;
        height: 15px;
    }
}