.search-bar-modal {
    border: #75747F 1px solid;
    border-radius: 7px;
    width: 395px;
    height: 42px;
    overflow: hidden;
    padding: 0px 10px;
}

.search-bar-modal input {
      border:none;
      width: 100%;
      height: 35px;
      background-color: transparent;
      font-size: 14px;
      color: #75747F
}

.search-bar-modal:hover {
    color: #1C1C30;
    border-color: #1C1C30;
}

.search-bar-modal:focus-within {
      color: #1C1C30;
      border-color: #1C1C30;
}

.search-bar-modal:focus-within input{
      color:#1C1C30;
}

.search-bar-modal .icon-icon-close:hover {
      color:#1C1C30;
}