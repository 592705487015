#header {
  .brand-logo {
    color: white;
    font-size: 1.15em;
    >div {
      background-color: $gray-1;
      padding-top: 20px;
      border-radius: 20px 20px 0px 0px;
    }
  }
}