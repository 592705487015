/* Customize the label (the custom-checkbox-container) */
.custom-checkbox-container {
	display: block;
	position: relative;
	padding: 6px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.label-active {
		font-style: normal;
		font-weight: normal;
		font-family: 'SFProTextMedium';
		color: #1C1C30;
	}
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	height: 15px;
	width: 15px;
	background-color: #eee;
	border-radius: 10px;
	border: 1px solid #75747F;
	border-radius: 3px;
    position: absolute;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover {
	background-color: #F0F0F2;
	border-radius: 10px;

	input ~ .checkmark {
		border: 1px solid #1C1C30;
	}
}

/* When the checkbox is checked, add a blue background */
// .custom-checkbox-container input:checked ~ .checkmark {
// 	background-color: #2196f3;
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .checkmark:after {
	background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 4L3.5 7L9.5 1' stroke='%23212121' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	z-index: 1000;
	width: 75%;
	height: 90%;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.custom-checkbox-container-disabled{
    opacity: 0.5;
    cursor: not-allowed !important;
}