

.input-autocomplete {
    .open {
        border-radius: 10px 10px 0 0 !important;   
    }
    .options {
        position:absolute;
        left: 0;
        right: 0;
        background-color: white;
        border: 1px solid #1C1C30;
        border-top: 0px;
        border-radius: 0 0 10px 10px;
        max-height: 155px;
        overflow-y: auto;
        overflow-x: hidden;

        .option {
            padding: 8px;
        }
        .option:hover {
            background-color: #F0F0F2;
            border-radius: 10px;
        }
    }
}