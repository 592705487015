@import '../../../styles/app/common/variables.scss';

.preset-view-selector:hover .icon-icon-views{
   color: #1C1C30 !important;
}

.preset-view-selector {
    all:unset;
    display: flex;
    align-items: center;
    position: relative;
    margin: 5px 0;
}
