.smartlabel-image-wrap {
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }

  .smartlabel-image {
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
  }

  .smartLab-image-wrap {
    width: 150px;
    min-width: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 0 20px 20px 0;
    background-position: center;
    overflow: hidden;
    position: relative;
  }

  .smartLab-image {
    width: 150px;
    height: 200px;
    // max-width: 400px;
    // max-height: 400px;
    overflow: hidden;
    position: relative;
    border-radius: 0 20px 20px 0;
    background-position: center;
    background-size: cover;
  }

  .article-image-wrap {
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    overflow: hidden;
    position: relative;
  }

  .article-image {
    overflow: hidden;
    position: relative;
    border-radius: 8px !important;
    overflow: hidden;
    background-position: center;
    background-size: cover;
  }

  .article-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }