.table-v1-3 {
    overflow: auto;
    width: 100%;
    height: 100%;
}

table{
    width: 100%;
}

thead {
    background-color: #FFFFFF;
    box-shadow: inset 0 1px 0 inherit, inset 0 -2px 0 inherit;
    padding-top: 5px;
    position: sticky;
    top: 0;
    z-index: 3;
}

.msg-art-not-found{
    display: flex;
    flex: 1 1 auto;
    position: sticky;
    left:0;
    justify-content: center;
    align-items: center;
}

.article-info {
    position: sticky !important;
    left: 0px;
    background-color: #FFFFFF;
    z-index: 2;
    max-width: 200px !important;
    min-width: 200px !important;
}

.striped .article-info {
        background-color: #FBFBFC;
    }