
@import '../../../styles/app/common/variables.scss';


.table-menu {
    background-color: transparent;
    cursor: pointer;
}
.table-menu .icon-div {
    width: 30px;
    height: 30px;
    // border-radius: 7px;
}

.table-menu:hover {
    color: #1C1C30;
}
.table-menu .active {
    background-color: #F0F0F2;
}

.table-menu > .dropdown-menu > button {
    color: unset;
    border: none;
    background-color: transparent;
    padding: 6px 10px !important;
    margin: 2px auto;
    width: 95%
}

.table-menu > .dropdown-menu > button:hover{
    color: #494853;
    background-color: #F0F0F2 !important;
    border-radius: 8px;
}