/* ========================================================================
     Component: sidebar
 ========================================================================== */

$sidebar-bg: $gray-1;

$sidebar-item-color: #515253;
$sidebar-item-color-active: $nav-top-bg;
$sidebar-item-bg-active: #F0F0F2; // darken($sidebar-bg, 1%);

$sidebar-icon-color: inherits;
$sidebar-icon-color-active: $nav-top-bg;

$sidebar-bullet-color: rgba(0, 0, 0, 0.25);
$sidebar-bullet-color-active: $sidebar-icon-color-active;

$sidebar-heading-color: #919da8;
$sidebar-label-color: $sidebar-item-color;

$submenu-header-hg: 40px; // Submenu header when hover collapse sidebar
$sidebar-item-border-wd: 3px;
$scrollbar-wd: 17px;

$sidebar-light-brown: #F9F9F9;
$sidebar-hover: #F9F9F9;

$dark-blue-60: #75747F;
$dark-blue-36: #ACACB2;
$text-menu-hover: #1C1C30;


.sidebar {
  height: 100%;
  padding-bottom: 10px;
  background-color: #F0F0F2 !important;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; // draw a divider for the sidebar
  border-radius: 10px;

  &:after {
    content: '';
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    -webkit-transform: translateZ(0px);
  }

  .nav-heading {
    padding: 12px 15px;
    color: $sidebar-heading-color;
    font-size: 13px;
    letter-spacing: 0.035em;
    pointer-events: none;
    cursor: default;
  }
}

// Items
// First main level
.sidebar-nav {
  position: relative;
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; // Only the first item in sidebar
  top: 10px;
  > .nav-heading:first-child {
    padding-top: 20px;

  }

  > .sidebar-item,
  > .submenu-wrap {
    display: flex;
    align-items: center;
    height: 33px;
    border-radius: 8px;
    margin: 10px 20px;
    &:hover {
      text-decoration: none;
      outline: none;
     } 
    &.selected {
      background-color: #F9F9F9;
      border-color: #F9F9F9;
    }
  }
  .badge {
    display: block;
  }
}

// Hides the scrollbar only on desktops
$tablet-landscape: 1025px;
@media only screen and (min-width: $tablet-landscape) {
  .sidebar:not(.show-scrollbar) {
    margin-right: -$scrollbar-wd;
    overflow-y: scroll;
  }
}

// Desktop styles
// ---------------------------------
@include media-breakpoint-up(md) {
  .sidebar > .sidebar-nav {
    .badge {
      margin: 2px 0 0 0;
    }
  }
} // @media @desktop

.aside-collapsed-text {
  .sidebar {
    > .sidebar-nav {
      > .sidebar-item > .nav-item > span,
      > .sidebar-item > a > span {
        display: block !important;
        font-size: 12px;
      }

      > .sidebar-item {
        width: $aside-wd-collapsed-text - 2;
        > a {
          padding: 14px 0;
          > em {
            font-size: 1.4em;
          }
        }
      }
    }
  }
}


