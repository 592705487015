.Toastify__toast.border-radius-8 {
  @include media-breakpoint-up(sm) {
    border-radius: 8px;
  }
}

.Toastify__toast .Toastify__toast-body {
  white-space: break-spaces;
}

.Toastify__toast.basic {
  background-color: #F0F0F2 !important;
  border-radius: 8px;
  color: #1C1C30;
}

.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 350px;
  box-sizing: border-box;
  color: #1C1C30;
}

.Toastify__toast--default {
  background: #F0F0F2;
  color: #1C1C30; 
  };

.Toastify__toast--info {
  background-color: #F0F0F2;
  };

.Toastify__toast--success {
  background-color: #F0F0F2;
  };

.Toastify__toast--warning {
  background-color: #F0F0F2;
  }

.Toastify__toast--error {
  background-color: #F0F0F2;
  }

.Toastify__toast-body {
  margin: auto 0;
  flex: 1; 
  }

.Toastify__close-button {
  color: #1C1C30;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: center;
  };

.Toastify__close-button--default {
  color: #000;
  opacity: 0.3; 
  };

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1; 
  };

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  animation: Toastify__trackProgress linear 1 forwards;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left; 
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right; 
  };

.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); 
  }
