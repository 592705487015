.search-bar {
    border: 1px solid transparent;
    border-radius:7px;
    width: 35px;
    height: 42px;
    overflow: hidden;
    transition: width 0.3s, border-color 0.3s;
}
  .search-bar-active {
    border: #75747F 1px solid;
    border-radius: 7px;
    width: 235px;
    height: 42px;
    transition: width 0.3s, border-color 1s;
    overflow: hidden
  }
  .search-bar-active input {
      border:none;
      width: 200px;
      height: 35px;
      background-color: transparent;
      margin-left: 10px;
      font-size: 14px;
      color: #75747F
  }

  .search-bar:hover,
  .search-bar-active:hover {
    color: #1C1C30;
    cursor: pointer;
  }

  .search-bar-active:hover {
    border-color: #1C1C30;
  }

  .search-bar-active:focus-within {
      color: #1C1C30;
      border-color: #1C1C30;
  }

  .search-bar-active:focus-within input{
      color:#1C1C30;
  }
