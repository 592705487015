.results-text {
  padding: 0.25em;
  opacity: 0.5;
}

.filter {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  .filter-inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    // align-items: center;
    // Form
    & > div {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .download-list {
    & > .download-icon {
      font-size: 1.8rem;
      margin-right: 0.5rem;
      line-height: inherit !important;
      &.active {
        cursor: pointer;
      }
    }
  }
}
