.supplier-detail {
    &__name {
        max-width: 120px;
    }

    &__custom {
        max-width: 95%;

        & span[data-name='from'] {
            color: #1C1C30;
        }
    }
}

.font-sfpro-medium.supplier-detail__name > span {
    font-family: "SFProTextMedium" !important;
}

.modal {
    &__header {
        background: #F6F6F7;
		width: 560px;
		z-index: 1000;
		top: 0px;
		right: 20px;
		border-radius: 20px 0 0 0;

        & > span {
            cursor: pointer;
        }
    }

    &__body {
        margin-top: 53px;
    }
}

.item-indicator-container {
    gap: 8px;
	border-left-style: dotted;
	border-left-width: 2px;

    &::before {
        content: ' ';
        width: 10px;
        height: 10px;
        background-color: #F6F6F7;
        top: 0px;
        left: -5px;
        position: absolute;
    }

    &::after {
        content: ' ';
        width: 10px;
        height: 6px;
        background-color: #F6F6F7;
        bottom: 0px;
        left: -5px;
        position: absolute;
    }
}