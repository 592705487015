// OLD TYPOGRAPHY FUTURA
@font-face {
  font-family: 'FuturaPTWebLight';
  src: url('../fonts/Futura-PT-Light/Futura-PT_Light.woff2') format('woff2'),
    url('../fonts/Futura-PT-Light/Futura-PT_Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'FuturaPTWebMedium';
  src: url('../fonts/Futura-PT-Medium/Futura-PT_Medium.woff2') format('woff2'),
    url('../fonts/Futura-PT-Medium/Futura-PT_Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'FuturaPTWebBook';
  src: url('../fonts/Futura-PT-Book/Futura-PT_Book.woff2') format('woff2'),
    url('../fonts/Futura-PT-Book/Futura-PT_Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// SF Pro Text Typography
@font-face {
  font-family: 'SFProTextLight';
  src: url('../fonts/SF-Pro-Text-Light/SF-Pro-Text-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SFProTextMedium';
  src: url('../fonts/SF-Pro-Text-Medium/SF-Pro-Text-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SFProTextRegular';
  src: url('../fonts/SF-Pro-Text-Regular/SF-Pro-Text-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

:root {
  --font-futura-light: 'FuturaPTWebLight', sans-serif;
  --font-futura-medium: 'FuturaPTWebMedium', sans-serif;
  --font-futura-book: 'FuturaPTWebBook', sans-serif;
  --font-sfpro-light: 'SFProTextLight', sans-serif;
  --font-sfpro-medium: 'SFProTextMedium', sans-serif;
  --font-sfpro-regular: 'SFProTextRegular', sans-serif;
}

.font-futura-light {
  font-family: var(--font-futura-light);
  // letter-spacing: 2px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

.font-futura-medium {
  font-family: var(--font-futura-medium);
  // letter-spacing: 2px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

.font-futura-book {
  font-family: var(--font-futura-book);
  // letter-spacing: 2px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

.font-sfpro-light {
  font-family: var(--font-sfpro-light);
  // letter-spacing: 2px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

.font-sfpro-regular {
  font-family: var(--font-sfpro-regular);
  // letter-spacing: 2px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

.font-sfpro-medium {
  font-family: var(--font-sfpro-medium);
  // letter-spacing: 2px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}